<!--Todo: ISKOLA-->

<template>
    <v-row justify="center">
        <v-dialog v-model="dialog.show" persistent max-width="600px">

            <v-card>
                <v-card-title>
                    <span class="headline">Egyesület hozzáadása szerződési időszakhoz</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-select label="Egyesület" prepend-inner-icon="fa-map-marker" rounded outlined
									:items="KAClubsWithoutContractPeriod" item-text="name" item-value="id" v-model="dialog.clubId" />
                        <v-checkbox v-model="dialog.massAdd" label="Tömeges hozzáadás"/>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog.show = false">
                        Mégsem
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="addClub()"
                        v-if="dialog.clubId">
                        Hozzáadás
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
export default {
    props: ['contractPeriodId', "clubs", "show"],
    components: {

    },
    data: function () {
        return {
            allKAClubs: [],
            dialog: {
                show: false,
                clubId: null,
                massAdd: false
            }
        }
    },
    computed: {
        KAClubsWithoutContractPeriod() {
            return this.allKAClubs.filter(KAC => this.clubs.findIndex(C => C.id == KAC.id) == -1).map(KAC => {
                return {
                    name: KAC.MIRClub.name,
                    id: KAC.id
                }
            })
        }
    },
    watch: {
        show() {
            this.dialog.show=this.show;
        },
        'dialog.show': function() {
            this.show=this.dialog.show
        }
    },
    methods: {
        getClubs: function () {
            this.axios({ url: `club/`, method: 'GET' }).then(response => {
                if (response.data.success) this.allKAClubs = response.data.data.KAClubs
            })
        },
        addClub: function () {
            this.axios({url: `contractPeriod/club`, method: 'POST', data: {clubId: this.dialog.clubId, contractPeriodId: this.contractPeriodId}}).then(response => {
                if(response.data.success) {
                    this.clubs.push(this.allKAClubs.find(KAC => KAC.id==this.dialog.clubId));
                    this.dialog.clubId=null;
                    this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
                    this.dialog.show=this.dialog.massAdd;
                }
            })
        },
    },
    mounted() {
        this.getClubs()
    }
}


</script>
  