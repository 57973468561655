<template>
	<div>
		<template>
			<v-data-table :headers="tables.clubs.headers" :items="KAClubs" :itemsPerPage="options.ItemsPerPage">
				<template v-slot:top>
					<v-row>
						<v-col cols="12" sm="11">
							<!--<v-text-field
			          v-model="options.search"
			          label="Keresés"
			          prepend-inner-icon="fa-search"
			          class="mx-4"
								rounded
								outlined
			        />-->
						</v-col>
						<v-col cols="12" sm="1" v-if="!contractPeriodId">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{ on, attrs }">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="openAddKAClubDialog()">
										<v-icon>fa-plus</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
						</v-col>
						<v-col cols="12" sm="1" v-if="contractPeriodId">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{ on, attrs }">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="addKAClubToContractPeriodDialogShow=true">
										<v-icon>fa-plus</v-icon>
									</v-btn>
								</template>
							</v-tooltip>
						</v-col>
					</v-row>
				</template>
				<!--<template v-slot:footer>
          <v-row align="center" justify="end">
            <v-col cols="12" sm="3" align="center">
              <v-select
                align="center"
                label="Csoportok egy oldalon"
                :items="[10, 15, 25, 50]"
                v-model="options.itemsPerPage"
                prepend-inner-icon="fa-users"
                rounded
                outlined
                @change="options.page = options.page>Math.ceil(totalGroups/options.itemsPerPage)?Math.ceil(totalGroups/options.itemsPerPage):options.page, getGroups()"
              />
            </v-col>
            <v-col cols="12" sm="1" align="center" justify="center">
              {{(options.page-1)*options.itemsPerPage+1}}-{{((options.page-1)*options.itemsPerPage+options.itemsPerPage)>totalGroups?totalGroups:((options.page-1)*options.itemsPerPage+options.itemsPerPage)}}/{{totalGroups}}
            </v-col>
            <v-col cols="12" sm="2" align="center">
              <v-btn icon :disabled="options.page<2" @click="changePage(false)"><v-icon>fa-arrow-left</v-icon></v-btn>{{options.page}}. oldal<v-btn icon :disabled="options.page==Math.ceil(totalGroups/options.itemsPerPage)" @click="changePage(true)"><v-icon>fa-arrow-right</v-icon></v-btn>
            </v-col>
          </v-row>
        </template>-->
				<template v-slot:item.Region="{ item }">
					{{ item.Region.name }}
				</template>
				<template v-slot:item.name="{ item }">
					{{ item.MIRClub.name }}
				</template>
				<template v-slot:item.shortname="{ item }">
					{{ item.MIRClub.shortname }}
				</template>
				<template v-slot:item.chairman="{ item }">
					<span v-if="item.Chairman">
						<v-btn text :to="{ name: 'user', params: { id: item.Chairman.id } }">{{ item.Chairman.fullname
						}}
						</v-btn>
					</span>
					<span v-else>
						{{ item.MIRClub.chairman }}
					</span>
				</template>
				<template v-slot:item.departmentDirector="{ item }">
					<span v-if="item.DepartmentDirector">
						<v-btn text :to="{ name: 'user', params: { id: item.DepartmentDirector.id } }">
							{{ item.DepartmentDirector.fullname }}</v-btn>
					</span>
					<span v-else>
						{{ item.MIRClub.departmentDirector }}
					</span>
				</template>
				<template v-slot:item.KAAdmin="{ item }">
					<v-btn v-if="item.KAAdmin" text :to="{ name: 'user', params: { id: item.KAAdmin.id } }">
						{{ item.KAAdmin.fullname }}</v-btn>
				</template>
				<template v-slot:item.groupCount="{ item }">
					{{ groupCount(item) }}
				</template>
				<template v-slot:item.validContract="{ item }">
					<v-icon v-bind="attrs" v-on="on" color="success" v-if="item.ContractPeriods.length>0">fa-check</v-icon>
                	<v-icon v-bind="attrs" v-on="on" color="red accent-4" v-else>fa-times</v-icon>
				</template>
				<template v-slot:item.view="{ item }">
					<v-btn icon :href="$router.resolve({ name: 'club', params: { id: item.id } }).href" target="_blank">
						<v-icon>fa-eye</v-icon>
					</v-btn>
				</template>

			</v-data-table>
			<v-row justify="center">
				<v-dialog v-model="dialog.show" persistent max-width="600px">

					<v-card>
						<v-card-title>
							<span class="headline">Új egyesület</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-select label="Egyesület" prepend-inner-icon="fa-map-marker" rounded outlined
									:items="MIRClubs" item-text="name" item-value="id" v-model="dialog.club.id" />
								<v-select label="Régió" prepend-inner-icon="fa-map-marked" rounded outlined
									:items="regions" item-text="name" item-value="id" v-model="dialog.club.RegionId" />
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="dialog.show = false">
								Mégsem
							</v-btn>
							<v-btn color="blue darken-1" text @click="addKAClub()"
								v-if="dialog.club.id && dialog.club.RegionId">
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<addKAClubToContractPeriodDialog v-if="contractPeriodId" :show="addKAClubToContractPeriodDialogShow" :clubs="KAClubs" :contractPeriodId="contractPeriodId"/>
			</v-row>

		</template>
	</div>
</template>

<script>
import router from '../router';
import roles from '../plugins/roles'
import addKAClubToContractPeriodDialog from './contractPeriod-addClub.vue'
export default {
	props: ['contractPeriodId'],
	components: {
		addKAClubToContractPeriodDialog
	},
	data: function () {
		return {
			KAClubs: [],
			MIRClubs: [],
			regions: [],
			options: {
				page: 1,
				itemsPerPage: 25,
				search: ""
			},
			totalClubs: 1,
			tables: {
				clubs: {
					headers: [
						...(this.$store.getters.userRole == roles.nationalCoordinator ? [{
							text: 'Régió',
							align: 'center',
							sortable: false,
							filterable: false,
							value: 'Region'
						}] : []),
						{
							text: "Név",
							align: "center",
							sortable: true,
							filterable: true,
							value: "name"
						},
						{
							text: "Rövid név",
							align: "center",
							sortable: false,
							filterable: true,
							value: "shortname"
						},
						{
							text: 'Elnök',
							align: "center",
							sortable: false,
							filterable: false,
							value: "chairman"
						},
						{
							text: 'Szakosztályvezető',
							align: "center",
							sortable: false,
							filterable: false,
							value: "departmentDirector"
						},
						{
							text: "Kölyökatléta-felelős",
							align: "center",
							sortable: false,
							filterable: false,
							value: "KAAdmin"
						},
						{
							text: "Csoportok száma",
							align: "center",
							sortable: false,
							filterable: false,
							value: "groupCount"
						},
						{
							text: "Megtekintés",
							align: "center",
							sortable: false,
							filterable: false,
							value: "view"
						}
					]
				}
			},
			dialog: {
				show: false,
				club: {
					id: null,
					RegionId: null
				}
			},
			addKAClubToContractPeriodDialogShow: false
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		addKAClub: function () {
			this.axios({ url: "club", method: "POST", data: { club: this.dialog.club } }).then((response) => {
				if (response.data.success) {
					this.dialog.show = false
					this.getClubs()
					this.$store.commit('setSnack', 'A hozzáadás sikeresen megtörtént.')
					//this.$router.push({name: 'club', params: {id: this.dialog.club.id}})
				}
			})
		},
		search: function () {
			this.options.page = 1;
			this.getClubs()
		},
		getClubs: function () {
			this.axios({ url: "club", method: "GET", params: this.options }).then((response) => {
				if (response.data.success) {
					this.KAClubs = response.data.data.KAClubs
					this.totalClubs = response.data.data.totalClubs
				}
			})
		},
		openAddKAClubDialog: function () {
			this.dialog.club.id = null;
			if (this.MIRClubs.length == 0)
				this.axios({ url: "club/addClub", method: "GET" }).then((response) => {
					if (response.data.success) {
						this.MIRClubs = response.data.data.MIRClubs
						this.regions = response.data.data.regions;
						this.dialog.show = true;
					}
				})
			else this.dialog.show = true
		},
		groupCount: function (club) {
			const groupsWithEnoughKidAthletes = club.MIRClub.Groups.filter(G => G.KidAthletes.length >= this.config.minKidAthletesInAGroup).length;
			const groupsWithoutEnoughKidAthletes = club.MIRClub.Groups.length - groupsWithEnoughKidAthletes;
			return (groupsWithEnoughKidAthletes + " (+" + groupsWithoutEnoughKidAthletes + " nem teljes csoport)")
		}
	},
	mounted() {
		if (this.contractPeriodId) this.options.contractPeriodId = this.contractPeriodId;
		if (!this.contractPeriodId) {
			this.tables.clubs.headers.push({
				text: "Aktuális szerződés",
				align: "center",
				sortable: false,
				filterable: false,
				value: "validContract"
			})

			var csere = this.tables.clubs.headers[this.tables.clubs.headers.length-1];
			this.tables.clubs.headers[this.tables.clubs.headers.length-1] = this.tables.clubs.headers[this.tables.clubs.headers.length-2];
			this.tables.clubs.headers[this.tables.clubs.headers.length-2] = csere;
		}
		this.getClubs()
	}
}
</script>
