var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.tables.clubs.headers,"items":_vm.KAClubs,"itemsPerPage":_vm.options.ItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"11"}}),(!_vm.contractPeriodId)?_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){return _vm.openAddKAClubDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}],null,false,1991716882)},[_vm._v(" Hozzáadás ")])],1):_vm._e(),(_vm.contractPeriodId)?_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.addKAClubToContractPeriodDialogShow=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}],null,false,1051651209)},[_vm._v(" Hozzáadás ")])],1):_vm._e()],1)]},proxy:true},{key:"item.Region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Region.name)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.MIRClub.name)+" ")]}},{key:"item.shortname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.MIRClub.shortname)+" ")]}},{key:"item.chairman",fn:function(ref){
var item = ref.item;
return [(item.Chairman)?_c('span',[_c('v-btn',{attrs:{"text":"","to":{ name: 'user', params: { id: item.Chairman.id } }}},[_vm._v(_vm._s(item.Chairman.fullname)+" ")])],1):_c('span',[_vm._v(" "+_vm._s(item.MIRClub.chairman)+" ")])]}},{key:"item.departmentDirector",fn:function(ref){
var item = ref.item;
return [(item.DepartmentDirector)?_c('span',[_c('v-btn',{attrs:{"text":"","to":{ name: 'user', params: { id: item.DepartmentDirector.id } }}},[_vm._v(" "+_vm._s(item.DepartmentDirector.fullname))])],1):_c('span',[_vm._v(" "+_vm._s(item.MIRClub.departmentDirector)+" ")])]}},{key:"item.KAAdmin",fn:function(ref){
var item = ref.item;
return [(item.KAAdmin)?_c('v-btn',{attrs:{"text":"","to":{ name: 'user', params: { id: item.KAAdmin.id } }}},[_vm._v(" "+_vm._s(item.KAAdmin.fullname))]):_vm._e()]}},{key:"item.groupCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.groupCount(item))+" ")]}},{key:"item.validContract",fn:function(ref){
var item = ref.item;
return [(item.ContractPeriods.length>0)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v("fa-check")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red accent-4"}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v("fa-times")])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":_vm.$router.resolve({ name: 'club', params: { id: item.id } }).href,"target":"_blank"}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Új egyesület")])]),_c('v-card-text',[_c('v-container',[_c('v-select',{attrs:{"label":"Egyesület","prepend-inner-icon":"fa-map-marker","rounded":"","outlined":"","items":_vm.MIRClubs,"item-text":"name","item-value":"id"},model:{value:(_vm.dialog.club.id),callback:function ($$v) {_vm.$set(_vm.dialog.club, "id", $$v)},expression:"dialog.club.id"}}),_c('v-select',{attrs:{"label":"Régió","prepend-inner-icon":"fa-map-marked","rounded":"","outlined":"","items":_vm.regions,"item-text":"name","item-value":"id"},model:{value:(_vm.dialog.club.RegionId),callback:function ($$v) {_vm.$set(_vm.dialog.club, "RegionId", $$v)},expression:"dialog.club.RegionId"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" Mégsem ")]),(_vm.dialog.club.id && _vm.dialog.club.RegionId)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addKAClub()}}},[_vm._v(" Hozzáadás ")]):_vm._e()],1)],1)],1),(_vm.contractPeriodId)?_c('addKAClubToContractPeriodDialog',{attrs:{"show":_vm.addKAClubToContractPeriodDialogShow,"clubs":_vm.KAClubs,"contractPeriodId":_vm.contractPeriodId}}):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }